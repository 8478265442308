<template>
  <div v-if="Object.keys(generator_info).length !== 0">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <b>Generator Brand: </b> {{ generator_info.generator_brand }}<br>
            <b>Engine Brand: </b> {{ generator_info.engine_brand }}<br>
          </div>
          <div class="col-sm-6">
            <b>Asset Tag No.: </b> {{ generator_info.asset_tag_no }}<br>
            <b>Model No.: </b> {{ generator_info.model_no }}<br>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.year_id" v-validate="{ required: true }" id="year_id" name="year_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a year</option>
                <option v-for="(year, index) in years()" :key="index" :value="index">{{ year }}</option>
              </select>
              <div class="invalid-feedback">Year is required</div>
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <a-tooltip placement="top" v-if="generator_fuel_consumptions.length > 0">
            <template slot="title"><span>Download Report</span></template>
            <a-button :loading="downloadBtnLoading" class="btn btn-sm btn-success float-right mb-1" @click.prevent='reportDownload()'>
              <i class="fa fa-file-excel-o mr-1"/> Download Report
            </a-button>
          </a-tooltip>
          <table class="table table-sm table-bordered text-center">
            <thead>
            <tr>
              <th>#</th>
              <th>Year</th>
              <th>Month</th>
              <th>Total Consumption(Ltr)</th>
              <th>Total Run Time(Hr)</th>
              <th>Consumption/Run Time(Ltr/Hr)</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(generator_fuel_consumption, index) in generator_fuel_consumptions" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ generator_fuel_consumption.year }}</td>
              <td>{{ customConsumptionDateFormat(generator_fuel_consumption.month) }}</td>
              <td>{{ generator_fuel_consumption.total_consumption_liter }}</td>
              <td>{{ generator_fuel_consumption.total_running_hours }}</td>
              <td>{{ calculateFuelRatio(generator_fuel_consumption.total_consumption_liter, generator_fuel_consumption.total_running_hours) }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="generator_fuel_consumptions.length === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import moment from 'moment'

export default {
  name: 'create',
  components: { Empty },
  data() {
    return {
      generator_info: {},
      generator_fuel_consumptions: {},
      btnLoading: false,
      downloadBtnLoading: false,
      loading: false,
      loader: false,
      flag: false,
      show: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        year_id: '',
        generator_info_id: '',
      },
    }
  },
  mounted: function () {
    this.getGeneratorInfo()
  },
  methods: {
    customConsumptionDateFormat(date) {
      const dateIndex = date - 1
      return moment().month(dateIndex).format('MMMM')
    },
    calculateFuelRatio(totalConsumptionLiter, totalRunningHours) {
      return (totalConsumptionLiter / totalRunningHours).toFixed(2)
    },
    getGeneratorInfo() {
      const generatorInfoId = this.$route.params.generator_info_id
      apiClient.get('api/generator-info/' + generatorInfoId)
        .then(response => {
          this.generator_info = response.data.generator_info
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          this.search_data.generator_info_id = this.$route.params.generator_info_id
          apiClient.post('api/generator-fuel-consumption/report', this.search_data)
            .then(response => {
              this.flag = true
              this.loading = false
              this.btnLoading = false
              this.generator_fuel_consumptions = response.data.generator_fuel_consumptions
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    months() {
      return this.$months()
    },
    years() {
      return this.$years()
    },
    reportDownload() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.downloadBtnLoading = true
          this.search_data.generator_info_id = this.$route.params.generator_info_id
          apiClient.post('api/generator-fuel-consumption/report/download', this.search_data, { responseType: 'blob' }).then(response => {
            this.downloadBtnLoading = false
            if (response.data.size === 68) {
              this.$notification.error({
                message: 'No data found, in this search query',
              })
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'generator_fuel_consumption_report.xlsx')
              document.body.appendChild(link)
              link.click()
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
